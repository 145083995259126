<template>
  <div type="button" class="select-none relative block w-full border-2 border-gray-200 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
    <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-200"></CubeTransparentIcon>
    <span class="mt-2 block text-sm font-medium text-gray-300"> Nic tu nie ma.. </span>
  </div>
</template>
<script>
    import { CubeTransparentIcon } from '@heroicons/vue/outline';
    export default {
        name: "EmptyState",
        components: {CubeTransparentIcon},
        data: function () {
            return {
            }
        }
    }
</script>